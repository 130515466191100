.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
  background-color: #006AB7;
  transition: opacity 0.75s, visibility 0.75s;
}

.loader-hidden {
  opacity: 0;
  visibility: hidden;
}

.loader svg {
  animation: loading 1.2s ease infinite;
}


@keyframes loading {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
}